var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-row',[(_vm.loader)?_c('div',{staticClass:"overlay"},[_c('b-spinner',{staticClass:"loader",attrs:{"variant":"primary","label":"Spinning"}})],1):_vm._e(),_c('b-col',{attrs:{"md":"6 pt-3"}},[_c('b-alert',{attrs:{"variant":_vm.isSuccess ? 'success' : 'danger',"show":_vm.showAlert,"dismissible":"","fade":""},on:{"dismissed":function($event){_vm.showAlert = false}}},[_vm._v(_vm._s(_vm.respMessage)+" ")])],1),(
      _vm.formType !== 'rfpTncView' &&
        _vm.formType !== 'bgvcView' &&
        _vm.formType !== 'rfpBidDocument' &&
        _vm.formType !== 'rfpBoqFilesView' &&
        _vm.formType !== 'bgvcDirectorsView'
    )?_c('b-col',{class:_vm.formType !== 'invoice' ? 'mt-3' : '',attrs:{"md":"12"}},[_c('input',{ref:"file",staticStyle:{"display":"none"},attrs:{"type":"file","multiple":"","id":"FileUpload1"},on:{"change":function($event){return _vm.selectFile()}}}),(_vm.formType !== 'FC_Billing_Generate_Bill' && _vm.formType !== 'purReq')?_c('b-button',{staticClass:"mr-3",attrs:{"variant":"info"},on:{"click":function($event){return _vm.browseFile()}}},[_vm._v("Add Files..")]):_vm._e(),(_vm.formType !== 'FC_Billing_Generate_Bill' && _vm.formType != 'purReq')?_c('b-button',{staticClass:"mr-3",attrs:{"variant":"success"},on:{"click":function($event){return _vm.checkUpload()}}},[_vm._v("Start Upload")]):_vm._e(),(_vm.formType == 'purReq' && _vm.apprStatus == 'DRAFT')?_c('b-button',{staticClass:"mr-3",attrs:{"variant":"info"},on:{"click":function($event){return _vm.browseFile()}}},[_vm._v("Add Files..")]):_vm._e(),(_vm.formType == 'purReq' && _vm.apprStatus == 'DRAFT')?_c('b-button',{staticClass:"mr-3",attrs:{"variant":"success"},on:{"click":function($event){return _vm.checkUpload()}}},[_vm._v("Start Upload")]):_vm._e(),(
        _vm.formType !== 'invoice' &&
          _vm.formType !== 'evaluation' &&
          _vm.formType !== 'provision' &&
          _vm.formType !== 'rfpTnc' &&
          _vm.formType !== 'rfpTncView' &&
          _vm.formType !== 'rfp' &&
          _vm.formType !== 'bgvc' &&
          _vm.formType !== 'FC_Billing_Generate_Bill' &&
          _vm.formType !== 'rfpBoqFiles' &&
          _vm.formType !== 'rfpBoqFilesView' &&
          _vm.formType !== 'rfpBoqFilesComparative' &&
          _vm.formType !== 'bgvcDirectors' &&
          _vm.formType !== 'purReq'
      )?_c('b-button',{staticClass:"mr-3",attrs:{"variant":"warning"}},[_vm._v("Cancel Upload")]):_vm._e(),(
        this.$route.params.wfType !== 'upload-doc' &&
          _vm.formType !== 'invoice' &&
            _vm.formType !== 'evaluation' &&
            _vm.formType !== 'provision' &&
            _vm.formType !== 'rfpTnc' &&
            _vm.formType !== 'rfpTncView' &&
            _vm.formType !== 'rfp' &&
            _vm.formType !== 'FC_Billing_Generate_Bill' &&
            _vm.formType !== 'bgvc' &&
            _vm.formType !== 'purReq' &&
            _vm.formType !== 'rfpBoqFiles' &&
            _vm.formType !== 'rfpBoqFilesView' &&
            _vm.formType !== 'rfpBoqFilesComparative' &&
            _vm.formType !== 'bgvcDirectors'
      )?_c('b-button',{staticClass:"mr-3",attrs:{"variant":"warning"},on:{"click":function($event){return _vm.logoutFromOpen()}}},[_vm._v("Logout")]):_vm._e()],1):_vm._e(),_c('b-col',{staticClass:"mt-0 text-centre pl-3",attrs:{"md":"12"}},[_c('div',{},[_c('b-table',{staticClass:"bg-white",attrs:{"striped":"","hover":"","outlined":"","items":_vm.uploadDocList,"fields":_vm.uploadDocFields,"show-empty":"","sticky-header":""},scopedSlots:_vm._u([{key:"cell(download)",fn:function(data){return [(data.item.docDetailId !== 0)?_c('a',{attrs:{"target":"_blank","href":_vm.downloadDocUrl +
                '/download/' +
                data.item.docDetailId +
                '?token=' +
                _vm.openkmAuthToken}},[_vm._v(" "+_vm._s(data.item.file_name))]):_c('span',[_vm._v(_vm._s(data.item.file_name))])]}},{key:"cell(file_name)",fn:function(data){return [(data.item.docDetailId !== 0)?_c('span',{staticStyle:{"color":"#007BFF","cursor":"pointer"},on:{"click":function($event){return _vm.showHideOpenModal(
                true,
                data.item.docDetailId,
                _vm.openkmAuthToken,
                data.item.file_name
              )}}},[_vm._v(" "+_vm._s(data.item.file_name))]):_c('span',[_vm._v(_vm._s(data.item.file_name))])]}},(
            _vm.formType === 'rfp' ||
              _vm.formType === 'rfpTnc' ||
              _vm.formType === 'rfpTncView' ||
              _vm.formType === 'bgvc' ||
              _vm.formType === 'bgvcView' ||
              _vm.formType === 'rfpBidDocument' ||
              _vm.formType === 'rfpBoqFiles' ||
              _vm.formType === 'rfpBoqFilesView' ||
              _vm.formType === 'rfpBoqFilesComparative' ||
              _vm.formType === 'bgvcDirectors' ||
              _vm.formType === 'bgvcDirectorsView'
          )?{key:"cell(preview)",fn:function(data){return [_c('span',{staticStyle:{"color":"#007BFF","cursor":"pointer"},on:{"click":function($event){return _vm.showHideOpenModal(
                true,
                data.item.docDetailId,
                _vm.openkmAuthToken,
                data.item.file_name
              )}}},[(data.item.docDetailId)?_c('b-icon',{attrs:{"icon":"eye-fill","font-scale":"1.5"}}):_vm._e()],1)]}}:null,(
            _vm.formType === 'rfp' ||
              _vm.formType === 'rfpTnc' ||
              _vm.formType === 'rfpTncView' ||
              _vm.formType === 'bgvc' ||
              _vm.formType === 'bgvcView' ||
              _vm.formType === 'rfpBidDocument' ||
              _vm.formType === 'rfpBoqFiles' ||
              _vm.formType === 'rfpBoqFilesView' ||
              _vm.formType === 'rfpBoqFilesComparative' ||
              _vm.formType === 'bgvcDirectors' ||
              _vm.formType === 'bgvcDirectorsView'
          )?{key:"cell(new_tab)",fn:function(data){return [_c('span',{staticStyle:{"color":"#007BFF","cursor":"pointer"},on:{"click":function($event){return _vm.openDocumentNewTab(
                data.item.docDetailId,
                data.item.file_name,
                _vm.openkmAuthToken
              )}}},[(data.item.docDetailId)?_c('b-icon',{attrs:{"icon":"arrow-up-right-circle-fill","font-scale":"1.5"}}):_vm._e()],1)]}}:null,{key:"cell(leName)",fn:function(data){return [_c('b-form-input',{attrs:{"type":"text","readonly":""},model:{value:(data.item.leName),callback:function ($$v) {_vm.$set(data.item, "leName", $$v)},expression:"data.item.leName"}})]}},{key:"cell(categoryName)",fn:function(data){return [_c('b-form-input',{attrs:{"type":"text","readonly":""},model:{value:(data.item.categoryName),callback:function ($$v) {_vm.$set(data.item, "categoryName", $$v)},expression:"data.item.categoryName"}})]}},{key:"cell(subcategoryName)",fn:function(data){return [_c('b-form-input',{attrs:{"type":"text","readonly":""},model:{value:(data.item.subcategoryName),callback:function ($$v) {_vm.$set(data.item, "subcategoryName", $$v)},expression:"data.item.subcategoryName"}})]}},{key:"cell(comments)",fn:function(data){return [_c('b-form-input',{attrs:{"type":"text","disabled":data.item.docDetailId !== 0},model:{value:(data.item.comments),callback:function ($$v) {_vm.$set(data.item, "comments", $$v)},expression:"data.item.comments"}})]}},{key:"cell(delete)",fn:function(data){return [(_vm.formType === 'invoice')?_c('div',[(
                (data.item.user_type === 'EMP' &&
                  data.item.user_id == _vm.userId) ||
                  data.item.docDetailId == 0
              )?_c('div',{on:{"click":function($event){return _vm.deleteRow(data.index, data.item.docDetailId)}}},[_c('b-img',{attrs:{"src":require('@/assets/imgs/delete.png'),"fluid":"","alt":"Vatika image"}})],1):_vm._e()]):_c('div',{on:{"click":function($event){return _vm.deleteRow(data.index, data.item.docDetailId)}}},[_c('b-img',{attrs:{"src":require('@/assets/imgs/delete.png'),"fluid":"","alt":"Vatika image"}})],1)]}}],null,true)})],1),_c('b-row',[_c('b-col',{staticClass:"p-0",attrs:{"md":"3"}},[_c('b-pagination',{staticClass:"my-0",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.perPage,"align":"fill","size":"sm"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1),(_vm.formType == 'invoice' || _vm.formType === 'purReq')?_c('div',{staticStyle:{"text-align":"right","margin-top":"20%"}},[_c('span',{staticStyle:{"color":"red"}},[_c('b',[_vm._v("*Maximum file size 15 MB")]),_c('br'),_c('b',[_vm._v("*only pdf, jpg, jpeg, png, zip, excel file allowed")])])]):_vm._e(),(
        _vm.formType == 'rfp' ||
          _vm.formType == 'rfpTnc' ||
          _vm.formType == 'bgvc' ||
          _vm.formType == 'rfpBoqFiles' ||
          _vm.formType == 'rfpBoqFilesComparative' ||
          _vm.formType == 'bgvcDirectors'
      )?_c('div',{staticStyle:{"text-align":"right","margin-top":"20%"}},[_c('span',{staticStyle:{"color":"red"}},[_c('b',[_vm._v("*Maximum file size 20 MB")]),_c('br'),_c('b',[_vm._v("*only pdf, jpg, jpeg, png, zip, excel file allowed")])])]):_vm._e()],1),_c('b-modal',{attrs:{"no-close-on-esc":"","no-close-on-backdrop":"","hide-footer":"","size":"xl","title":"Preview"},on:{"hidden":function($event){return _vm.showHideOpenModal(false)}},model:{value:(_vm.showOpenModal),callback:function ($$v) {_vm.showOpenModal=$$v},expression:"showOpenModal"}},[_c('previewDoc',{attrs:{"authToken":_vm.authToken,"docDtlId":_vm.docDtlId,"uploadedFileName":_vm.uploadedFileName}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }