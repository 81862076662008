import store from '../../../../../../store';
import URL_UTILITY from '@/app/utility/url.utility';
import commonHelper from '@/app/utility/common.helper.utility';
import previewDoc from '../uploadDocument/previewDoc';
export default {
  name: 'uploadDocument',
  components: {
    previewDoc
  },
  props: [
    'wfHdrId',
    'openkmAuthToken',
    'selectedGridData',
    'urlData',
    'formType',
    'userId',
    'apprStatus'
  ],
  watch: {
    currentPage: function() {
      this.getUploadedDocDtl();
    },
    perPage: function() {
      this.currentPage = 1;
      this.getUploadedDocDtl();
    }
  },
  data() {
    return {
      uploadedFileName: null,
      pageOptions: commonHelper.getPageOption(),
      perPage: commonHelper.perPageRecord,
      currentPage: 1,
      totalRows: null,
      progressBarMaxValue: 100,
      prgressbarValue: 0,
      downloadDocUrl: URL_UTILITY.getOpenKmDocDeleteUrl,
      selectedFilesArr: [],
      loader: false,
      editMode: false,
      isSuccess: false,
      showAlert: false,
      respMessage: null,
      uploadDocList: [],
      selectedObjectData: {
        le_id: null,
        le_name: null,
        nfa_cat_vset: null,
        nfa_cat: null,
        nfa_sub_cat_vset: null,
        nfa_sub_cat: null
      },
      srcDoc: '',
      uploadDocFields: [
        {
          key: 'file_name',
          label: 'Preview'
        },
        {
          key: 'leName'
        },
        {
          key: 'categoryName',
          label: 'Category'
        },
        {
          key: 'subcategoryName',
          label: 'Sub-Cat'
        },
        {
          key: 'comments'
        },
        {
          key: 'version'
        },
        {
          key: 'file_size'
        },
        {
          key: 'delete'
        }
      ],
      erpLeId: null,
      showOpenModal: false,
      docDtlId: null,
      authToken: null,
      downloadFlag: null,
      fileExtension: null
    };
  },
  created() {
    if (this.$route.params.wfType === 'upload-doc') {
      this.uploadDocFields.shift();
      this.uploadDocFields.unshift({
        key: 'download'
      });
    }
    if (this.$route.params.wfType !== 'upload-doc') {
      if (this.$store.state.shared.accessRights) {
        this.downloadFlag = this.$store.state.shared.accessRights.download_allow;
        if (this.downloadFlag) {
          this.uploadDocFields.unshift({
            key: 'download'
          });
        }
      }
    }
    this.selectedObjectData = this.selectedGridData;
    if (this.$route.params.wfType === 'upload-doc') {
      this.getLeIdBasedErpLeId(this.urlData.erp_le_id);
    } else if (
      this.formType === 'rfpTnc' ||
      this.formType === 'rfpTncView' ||
      this.formType === 'rfpBidDocument' ||
      this.formType === 'rfpBoqFiles' ||
      this.formType === 'rfpBoqFilesView' ||
      this.formType === 'rfpBoqFilesComparative'
    ) {
      this.getUploadedDocDtlTnC();
    } else if (
      this.formType === 'bgvc' ||
      this.formType === 'bgvcView' ||
      this.formType === 'bgvcDirectors' ||
      this.formType === 'bgvcDirectors' ||
      this.formType === 'bgvcDirectorsView'
    ) {
      this.getUploadedDocDtlBgvc();
    } else {
      this.getUploadedDocDtl();
    }
    if (
      this.formType === 'invoice' ||
      this.formType === 'provisions' ||
      this.formType === 'evaluation' ||
      this.formType === 'purReq'
    ) {
      if (this.formType === 'purReq' && this.apprStatus !== 'DRAFT') {
        this.uploadDocFields = [
          {
            key: 'download',
            label: 'File Name'
          },
          {
            key: 'file_size'
          }
        ];
      } else {
        this.uploadDocFields = [
          {
            key: 'download',
            label: 'File Name'
          },
          {
            key: 'file_size'
          },
          {
            key: 'delete'
          }
        ];
      }
    } else if (
      this.formType === 'rfpTnc' ||
      this.formType === 'rfp' ||
      this.formType === 'bgvc' ||
      this.formType === 'rfpBoqFiles' ||
      this.formType === 'bgvcDirectors'
    ) {
      this.uploadDocFields = [
        {
          key: 'download',
          label: 'File Name'
        },
        {
          key: 'preview',
          label: 'Preview',
          class: 'text-center'
        },
        {
          key: 'new_tab',
          class: 'text-center'
        },
        {
          key: 'file_size'
        },
        {
          key: 'delete'
        }
      ];
    } else if (
      this.formType === 'bgvcView' ||
      this.formType === 'rfpBoqFilesView' ||
      this.formType === 'rfpBoqFilesComparative' ||
      this.formType === 'rfpBidDocument' ||
      this.formType === 'rfpTncView' ||
      this.formType === 'bgvcDirectorsView'
    ) {
      this.uploadDocFields = [
        {
          key: 'download',
          label: 'File Name'
        },
        {
          key: 'preview',
          label: 'Preview',
          class: 'text-center'
        },
        {
          key: 'new_tab',
          class: 'text-center'
        },
        {
          key: 'file_size'
        }
      ];
    } else if (this.formType === 'FC_Billing_Generate_Bill') {
      this.uploadDocFields = [
        {
          key: 'download',
          label: 'File Name'
        },
        {
          key: 'leName',
          label: 'LE Name'
        },
        {
          key: 'file_size',
          label: 'Size'
        },
        {
          key: 'comments'
        },
        {
          key: 'created_by'
        },
        {
          key: 'creation_date'
        }
      ];
    }
  },
  methods: {
    showHideOpenModal(flag, docDtlId, authToken, fileName) {
      // to open km modal
      if (flag) {
        this.fileExtension = commonHelper.getFileExtension(fileName);
      }
      if (this.fileExtension === 'xlsx') {
        alert('Not Allowed');
      } else {
        this.showOpenModal = flag;
        this.docDtlId = docDtlId;
        this.authToken = authToken;
        this.uploadedFileName = fileName;
      }
    },
    getLeIdBasedErpLeId(erpOrgId) {
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getLeIdBasedErpLeId', erpOrgId)
        .then(resp => {
          this.loader = false;
          if (resp.status === 200) {
            this.erpLeId = resp.data.data.le_id;
            this.erpLeName = resp.data.data.le_name;
            this.getUploadedDocDelivery();
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getUploadedDocDelivery() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: this.urlData.menu_id,
        sub_menu_id: this.urlData.sub_menu_id,
        record_id: this.urlData.record_id
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.uploadDocList = [];
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page.map(data => {
                return {
                  fileRef: null,
                  file_name: data.original_file_name,
                  leId: this.erpLeId,
                  leName: this.erpLeName,
                  categoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat_vset
                    : null,
                  categoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat
                    : null,
                  subcategoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat_vset
                    : null,
                  subcategoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat
                    : null,
                  comments: data.comments,
                  version: data.doc_version,
                  file_size: this.formatBytes(data.file_size),
                  action: null,
                  docDetailId: data.doc_detail_id
                };
              });
              this.uploadDocList = docList;
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getUploadedDocDtl() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id:
          this.formType === 'invoice'
            ? 408
            : this.formType === 'evaluation'
            ? 374
            : store.state.shared.menuId,
        sub_menu_id:
          this.formType === 'invoice'
            ? 84
            : this.formType === 'evaluation'
            ? 87
            : 0,
        record_id: this.wfHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.uploadDocList = [];
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page.map(data => {
                return {
                  fileRef: null,
                  file_name: data.original_file_name,
                  leId: this.selectedObjectData
                    ? this.selectedObjectData.le_id
                    : null,
                  leName: this.selectedObjectData
                    ? this.selectedObjectData.le_name
                    : null,
                  categoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat_vset
                    : null,
                  categoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat
                    : null,
                  subcategoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat_vset
                    : null,
                  subcategoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat
                    : null,
                  comments: data.comments,
                  version: data.doc_version,
                  file_size: data.file_size
                    ? this.formatBytes(data.file_size)
                    : null,
                  action: null,
                  user_type: data.user_type,
                  user_id: data.user_id,
                  docDetailId: data.doc_detail_id,
                  created_by: data.created_by,
                  creation_date: data.creation_date
                };
              });
              this.uploadDocList = docList;
            }
            this.totalRows = response.data.data.total_elements;
            this.eventBus.$emit('totalEvaluationRows', this.totalRows);
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getUploadedDocDtlTnC() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: store.state.shared.menuId,
        sub_menu_id:
          this.formType === 'rfpBidDocument'
            ? 89
            : this.formType === 'rfpBoqFiles' ||
              this.formType === 'rfpBoqFilesView'
            ? 90
            : this.formType === 'rfpBoqFilesComparative'
            ? 91
            : 85,
        record_id: this.wfHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.uploadDocList = [];
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page.map(data => {
                return {
                  fileRef: null,
                  file_name: data.original_file_name,
                  leId: this.selectedObjectData
                    ? this.selectedObjectData.le_id
                    : null,
                  leName: this.selectedObjectData
                    ? this.selectedObjectData.le_name
                    : null,
                  categoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat_vset
                    : null,
                  categoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat
                    : null,
                  subcategoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat_vset
                    : null,
                  subcategoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat
                    : null,
                  comments: data.comments,
                  version: data.doc_version,
                  file_size: this.formatBytes(data.file_size),
                  action: null,
                  user_type: data.user_type,
                  user_id: data.user_id,
                  docDetailId: data.doc_detail_id
                };
              });
              this.uploadDocList = docList;
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    getUploadedDocDtlBgvc() {
      const payload = {
        _page: this.currentPage - 1,
        _limit: this.perPage,
        menu_id: sessionStorage.getItem('bgvcMenuId'),
        sub_menu_id:
          this.formType === 'bgvcDirectors' ||
          this.formType === 'bgvcDirectorsView'
            ? 1
            : 0,
        record_id: this.wfHdrId
      };
      this.loader = true;
      this.$store
        .dispatch('intWorkflow/getOpenKmDocDtl', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.uploadDocList = [];
            if (response.data.data.page.length > 0) {
              const docList = response.data.data.page.map(data => {
                return {
                  fileRef: null,
                  file_name: data.original_file_name,
                  leId: this.selectedObjectData
                    ? this.selectedObjectData.le_id
                    : null,
                  leName: this.selectedObjectData
                    ? this.selectedObjectData.le_name
                    : null,
                  categoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat_vset
                    : null,
                  categoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_cat
                    : null,
                  subcategoryCode: this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat_vset
                    : null,
                  subcategoryName: this.selectedObjectData
                    ? this.selectedObjectData.nfa_sub_cat
                    : null,
                  comments: data.comments,
                  version: data.doc_version,
                  file_size: this.formatBytes(data.file_size),
                  action: null,
                  user_type: data.user_type,
                  user_id: data.user_id,
                  docDetailId: data.doc_detail_id
                };
              });
              this.uploadDocList = docList;
            }
            this.totalRows = response.data.data.total_elements;
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    checkUpload() {
      if (this.$route.params.wfType === 'upload-doc') {
        this.uploadFileDelivery();
      } else if (this.formType === 'invoice') {
        this.uploadFileInvoiceApproval();
      } else if (
        this.formType === 'rfpTnc' ||
        this.formType === 'rfpBoqFiles' ||
        this.formType === 'rfpBoqFilesComparative'
      ) {
        this.uploadFileRfpTnC();
      } else if (this.formType === 'evaluation') {
        this.uploadPartyOnboardingEvaluation();
      } else if (this.formType === 'bgvcDirectors') {
        this.uploadFileBgvcDirectors();
      } else {
        this.uploadFile();
      }
    },
    uploadFileDelivery() {
      const filterDocData = this.uploadDocList.filter(
        data => data.docDetailId === 0
      );
      if (filterDocData.length > 0) {
        filterDocData.forEach(data => {
          let formData = new FormData();
          formData.append('file', data.fileRef.item(0));
          formData.append('token', this.openkmAuthToken);
          formData.append('category', data.categoryCode);
          formData.append('sub_category', data.subcategoryCode);
          formData.append('comment', data.comments);
          formData.append('doc_detail_id', 0);
          formData.append('menu_id', this.urlData.menu_id);
          formData.append('sub_menu_id', 0);
          formData.append('le_id', this.erpLeId);
          formData.append('record_id', this.urlData.record_id);
          this.loader = true;
          this.$store
            .dispatch('intWorkflow/uploadOpenKmDoc', formData)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                this.getUploadedDocDelivery();
                this.editMode = false;
                this.isSuccess = true;
                this.respMessage = response.data.message;
              } else {
                this.isSuccess = false;
                this.respMessage =
                  response.response.data.message || 'Something went wrong';
              }
            })
            .catch(() => {
              this.loader = false;
            });
        });
      }
    },
    uploadFile() {
      const filterDocData = this.uploadDocList.filter(
        data => data.docDetailId === 0
      );
      if (filterDocData.length > 0) {
        filterDocData.forEach(data => {
          let formData = new FormData();
          formData.append('file', data.fileRef.item(0));
          formData.append('token', this.openkmAuthToken);
          formData.append('category', data.categoryCode);
          formData.append('sub_category', data.subcategoryCode);
          formData.append('comment', data.comments);
          formData.append('doc_detail_id', 0);
          formData.append('menu_id', this.$store.state.shared.menuId);
          formData.append('sub_menu_id', 0);
          formData.append('le_id', data.leId);
          formData.append('record_id', this.wfHdrId);
          this.loader = true;
          this.$store
            .dispatch('intWorkflow/uploadOpenKmDoc', formData)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                if (this.formType === 'fmsTimeSheet') {
                  this.uploadDocFms();
                }
                this.getUploadedDocDtl();
                this.editMode = false;
                this.isSuccess = true;
                this.respMessage = response.data.message;
              } else {
                this.isSuccess = false;
                this.respMessage =
                  response.response.data.message || 'Something went wrong';
              }
            })
            .catch(() => {
              this.loader = false;
            });
        });
      }
    },
    uploadFileRfpTnC() {
      const filterDocData = this.uploadDocList.filter(
        data => data.docDetailId === 0
      );
      if (filterDocData.length > 0) {
        filterDocData.forEach(data => {
          let formData = new FormData();
          formData.append('file', data.fileRef.item(0));
          formData.append('token', this.openkmAuthToken);
          formData.append('category', data.categoryCode);
          formData.append('sub_category', data.subcategoryCode);
          formData.append('comment', data.comments);
          formData.append('doc_detail_id', 0);
          formData.append('menu_id', this.$store.state.shared.menuId);
          formData.append(
            'sub_menu_id',
            this.formType === 'rfpTnc'
              ? 85
              : this.formType === 'rfpBoqFilesComparative'
              ? 91
              : 90
          );
          formData.append('le_id', data.leId);
          formData.append('record_id', this.wfHdrId);
          this.loader = true;
          this.$store
            .dispatch('intWorkflow/uploadOpenKmDoc', formData)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                this.getUploadedDocDtlTnC();
                this.editMode = false;
                this.isSuccess = true;
                this.respMessage = response.data.message;
              } else {
                this.isSuccess = false;
                this.respMessage =
                  response.response.data.message || 'Something went wrong';
              }
            })
            .catch(() => {
              this.loader = false;
            });
        });
      }
    },
    uploadFileInvoiceApproval() {
      const filterDocData = this.uploadDocList.filter(
        data => data.docDetailId === 0
      );
      if (filterDocData.length > 0) {
        filterDocData.forEach(data => {
          let formData = new FormData();
          formData.append('file', data.fileRef.item(0));
          formData.append('token', this.openkmAuthToken);
          formData.append('category', data.categoryCode);
          formData.append('sub_category', data.subcategoryCode);
          formData.append('comment', data.comments);
          formData.append('doc_detail_id', 0);
          formData.append('menu_id', 408);
          formData.append('sub_menu_id', 84);
          formData.append('le_id', data.leId);
          formData.append('record_id', this.wfHdrId);
          this.loader = true;
          this.$store
            .dispatch('intWorkflow/uploadOpenKmDoc', formData)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                this.$emit('updateUploadedDocDtl');
                this.getUploadedDocDtl();
                this.editMode = false;
                this.isSuccess = true;
                this.respMessage = response.data.message;
              } else {
                this.isSuccess = false;
                this.respMessage =
                  response.response.data.message || 'Something went wrong';
              }
            })
            .catch(() => {
              this.loader = false;
            });
        });
      }
    },
    browseFile() {
      if (this.uploadDocList.length === 0) {
        this.uploadDocList = [];
      }
      this.$refs.file.click();
    },
    selectFile() {
      if (this.formType === 'invoice' || this.formType === 'provisions') {
        if (this.$refs.file.files[0].size > 15728640) {
          alert(
            this.$refs.file.files[0].name + ' file should be less than 15 MB.'
          );
        } else {
          this.selectedFilesArr.push(this.$refs.file.files);
          this.uploadDocList.push({
            fileRef: this.$refs.file.files,
            file_name: this.$refs.file.files[0].name,
            leId: null,
            leName: null,
            categoryCode: null,
            categoryName: null,
            subcategoryCode: null,
            subcategoryName: null,
            comments: '',
            version: 0,
            file_size: this.formatBytes(this.$refs.file.files[0].size),
            action: null,
            docDetailId: 0
          });
        }
      } else if (
        this.formType === 'rfp' ||
        this.formType === 'rfpTnc' ||
        this.formType === 'rfpTncView' ||
        this.formType === 'bgvc' ||
        this.formType === 'rfpBoqFiles' ||
        this.formType === 'rfpBoqFilesView' ||
        this.formType === 'rfpBoqFilesComparative' ||
        this.formType === 'bgvcDirectors'
      ) {
        if (this.$refs.file.files[0].size > 20971520) {
          alert(
            this.$refs.file.files[0].name +
              ' file should be less than or equal to 20 MB.'
          );
        } else {
          this.selectedFilesArr.push(this.$refs.file.files);
          this.uploadDocList.push({
            fileRef: this.$refs.file.files,
            file_name: this.$refs.file.files[0].name,
            leId: null,
            leName: null,
            categoryCode: null,
            categoryName: null,
            subcategoryCode: null,
            subcategoryName: null,
            comments: '',
            version: 0,
            file_size: this.formatBytes(this.$refs.file.files[0].size),
            action: null,
            docDetailId: 0
          });
        }
      } else {
        this.selectedFilesArr.push(this.$refs.file.files);
        this.uploadDocList.push({
          fileRef: this.$refs.file.files,
          file_name: this.$refs.file.files[0].name,
          leId: this.selectedObjectData.le_id
            ? this.selectedObjectData.le_id
            : this.erpLeId,
          leName: this.selectedObjectData.le_name
            ? this.selectedObjectData.le_name
            : this.erpLeName,
          categoryCode: this.selectedObjectData.nfa_cat_vset,
          categoryName: this.selectedObjectData.nfa_cat,
          subcategoryCode: this.selectedObjectData.nfa_sub_cat_vset,
          subcategoryName: this.selectedObjectData.nfa_sub_cat,
          comments: '',
          version: 0,
          file_size: this.formatBytes(this.$refs.file.files[0].size),
          action: null,
          docDetailId: 0
        });
      }
    },
    deleteRow(index, docDetailId) {
      const payload = {
        docDetailId: docDetailId,
        token: this.openkmAuthToken
      };
      if (docDetailId !== 0) {
        this.loader = true;
        this.$store
          .dispatch('intWorkflow/delteOpenKmDoc', payload)
          .then(response => {
            this.loader = false;
            this.showAlert = true;
            if (response.status === 200) {
              this.$emit('updateUploadedDocDtl');
              this.editMode = false;
              this.isSuccess = true;
              this.respMessage = response.data.message;
            } else {
              this.isSuccess = false;
              this.respMessage =
                response.response.data.message || 'Something went wrong';
            }
          })
          .catch(() => {
            this.loader = false;
          });
      }
      this.uploadDocList.splice(index, 1);
    },
    uploadDocFms() {
      const filterDocData = this.uploadDocList.filter(
        data => data.docDetailId === 0
      );
      if (filterDocData.length > 0) {
        filterDocData.forEach(data => {
          let formData = new FormData();
          formData.append('file', data.fileRef.item(0));
          formData.append('doc_detail_id', 0);
          formData.append('menu_id', this.$store.state.shared.menuId);
          formData.append('sub_menu_id', 0);
          formData.append('record_id', this.wfHdrId);
          this.loader = true;
          this.$store
            .dispatch('intWorkflow/uploadDocFms', formData)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 201) {
                this.editMode = false;
                this.isSuccess = true;
                this.respMessage = response.data.message;
              } else {
                this.isSuccess = false;
                this.respMessage =
                  response.response.data.message || 'Something went wrong';
              }
            })
            .catch(() => {
              this.loader = false;
            });
        });
      }
    },
    logoutFromOpen() {
      this.loader = true;
      const payload = {
        token: this.openkmAuthToken
      };
      this.$store
        .dispatch('intWorkflow/logoutOpenKm', payload)
        .then(response => {
          this.loader = false;
          if (response.status === 200) {
            this.$emit('closeOpenKmModal');
          }
        })
        .catch(() => {
          this.loader = false;
        });
    },
    formatBytes(bytes, decimals = 2) {
      if (bytes === 0) return '0 Bytes';
      const k = 1024;
      const dm = decimals < 0 ? 0 : decimals;
      const sizes = ['Bytes', 'KB', 'MB', 'GB'];
      const i = Math.floor(Math.log(bytes) / Math.log(k));
      return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    },
    uploadPartyOnboardingEvaluation() {
      const filterDocData = this.uploadDocList.filter(
        data => data.docDetailId === 0
      );
      if (filterDocData.length > 0) {
        filterDocData.forEach(data => {
          let formData = new FormData();
          formData.append('file', data.fileRef.item(0));
          formData.append('token', this.openkmAuthToken);
          formData.append('category', data.categoryCode);
          formData.append('sub_category', data.subcategoryCode);
          formData.append('comment', 'Evaluation');
          formData.append('doc_detail_id', 0);
          formData.append('menu_id', 374);
          formData.append('sub_menu_id', 87);
          formData.append('le_id', data.leId);
          formData.append('record_id', this.wfHdrId);
          this.loader = true;
          this.$store
            .dispatch('intWorkflow/uploadOpenKmDoc', formData)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                this.$emit('updateUploadedDocDtl');
                this.getUploadedDocDtl();
                this.editMode = false;
                this.isSuccess = true;
                this.respMessage = response.data.message;
              } else {
                this.isSuccess = false;
                this.respMessage =
                  response.response.data.message || 'Something went wrong';
              }
            })
            .catch(() => {
              this.loader = false;
            });
        });
      }
    },
    openDocumentNewTab(docDtlId, fileName, authToken) {
      this.fileExtension = commonHelper.getFileExtension(fileName);
      if (this.fileExtension === 'xlsx') {
        alert('Not Allowed');
      } else {
        const payload = {
          docDetailId: docDtlId,
          token: authToken,
          fileExtension: this.fileExtension
        };
        this.loader = true;
        commonHelper.previewImgDocParty(payload);
        this.loader = false;
      }
    },
    uploadFileBgvcDirectors() {
      const filterDocData = this.uploadDocList.filter(
        data => data.docDetailId === 0
      );
      if (filterDocData.length > 0) {
        filterDocData.forEach(data => {
          let formData = new FormData();
          formData.append('file', data.fileRef.item(0));
          formData.append('token', this.openkmAuthToken);
          formData.append('category', data.categoryCode);
          formData.append('sub_category', data.subcategoryCode);
          formData.append('comment', data.comments);
          formData.append('doc_detail_id', 0);
          formData.append('menu_id', sessionStorage.getItem('bgvcMenuId'));
          formData.append('sub_menu_id', 1);
          formData.append('le_id', data.leId);
          formData.append('record_id', this.wfHdrId);
          this.loader = true;
          this.$store
            .dispatch('intWorkflow/uploadOpenKmDoc', formData)
            .then(response => {
              this.loader = false;
              this.showAlert = true;
              if (response.status === 200) {
                this.getUploadedDocDtlBgvc();
                this.editMode = false;
                this.isSuccess = true;
                this.respMessage = response.data.message;
              } else {
                this.isSuccess = false;
                this.respMessage =
                  response.response.data.message || 'Something went wrong';
              }
            })
            .catch(() => {
              this.loader = false;
            });
        });
      }
    }
  },
  beforeDestroy() {
    this.eventBus.$off('totalEvaluationRows');
  }
};
