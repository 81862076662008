import http from '../../../app/services/httpServices';
import URL_UTILITY from '../../../app/utility/url.utility';

const addEditFmsGenBatch = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsGenBatchUrl, payload);
};

const getFmsGenBatchList = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsGenBatchUrl, payload);
};

const updateFmsBatchBillDates = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsBatchBillUrl, payload);
};

const saveBatchBill = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsBatchBillGenUrl, payload);
};
const getBillBatchesById = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFmsBatchBillUrl +
      '/' +
      payload.billBatchId +
      '?_page=' +
      payload.pagination._page +
      '&_limit=' +
      payload.pagination._limit,
    payload.searchParams
  );
};
const billingRequestGenerate = (context, payload) => {
  return http.postApi(URL_UTILITY.billingRequestGenerateUrl, payload);
};
const generateFinalBill = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsAdminUrl + '/final-bills', payload);
};
const getBatchErrorById = (context, payload) => {
  return http.getApi(
    URL_UTILITY.getFmsBatchBillUrl +
      '/' +
      payload.billBatchId +
      '/bill-batch-id',
    payload.pagination
  );
};
const generateTempBatchBill = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsGenBatchUrl + '-temp', payload);
};
const getTempBatchBill = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsGenBatchUrl + '-tmp', payload);
};
const updateBillHoldUnhold = (context, payload) => {
  return http.postApi(URL_UTILITY.updateBillHoldUnholdUrl, payload);
};
const billUploadDoc = (context, excelImportId) => {
  return http.postApi(
    URL_UTILITY.billUploadDocUrl + '/' + excelImportId + '/excel-upload'
  );
};
const getBillUploadData = (context, payload) => {
  return http.getApi(URL_UTILITY.getBillUploadUrl, payload);
};
const deleteBillUpload = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsBillsUrl + '-upload/delete-by-hdr-id', payload);
};
const getApplyReceiptUploadData = (context, payload) => {
  return http.getApi(URL_UTILITY.getApplyReceiptUploadUrl + '/receipt-apply-excel-upload', payload);
};
const deleteApplyReceiptUpload = (context, payload) => {
  return http.postApi(URL_UTILITY.getApplyReceiptUploadUrl + '/receipts-apply-loader-delete', payload);
};
const applyReceiptUploadDoc = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getApplyReceiptUploadUrl + '/receipt-apply/' + payload.excel_import_id + '/excel-upload'
  );
};
const updateFmsDueDate = (context, payload) => {
  return http.postApi(URL_UTILITY.getFmsBatchBillUrl + '/generate-due-date', payload);
};
const crystalBillUpload = (context, payload) => {
  return http.postApi(
    URL_UTILITY.getFmsAdminUrl + '/crys-bills-upload'+ '/' + payload.excelImportId + '/excel-upload' +'?prj_id='+ payload.prj_id + '&billing_period_from='+ payload.billing_period_from +'&billing_period_to='+ payload.billing_period_to
  );
};
const getcrystalBillUpload = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsAdminUrl + '/get-crystal-batch-loader', payload);
};
const getcrystalBillUploadById = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsAdminUrl + '/get-crystal-batch-loader/' +payload.batch_hdr_id , payload);
};
const getCrystalBillData = (context, payload) => {
  return http.getApi(URL_UTILITY.getFmsAdminUrl + '/get-crystal-batch-details/' + payload.billBatchId , payload);
};
export default {
  addEditFmsGenBatch,
  getFmsGenBatchList,
  updateFmsBatchBillDates,
  saveBatchBill,
  getBillBatchesById,
  billingRequestGenerate,
  getBatchErrorById,
  generateFinalBill,
  generateTempBatchBill,
  getTempBatchBill,
  updateBillHoldUnhold,
  billUploadDoc,
  getBillUploadData,
  deleteBillUpload,
  getApplyReceiptUploadData,
  deleteApplyReceiptUpload,
  applyReceiptUploadDoc,
  updateFmsDueDate,
  crystalBillUpload,
  getcrystalBillUpload,
  getcrystalBillUploadById,
  getCrystalBillData
};
